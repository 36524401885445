import { Text } from "@chakra-ui/react";

function Following() {
  return (
    <>
      <Text as="b" fontSize="3xl">
        Following page
      </Text>
    </>
  );
}

export default Following;
