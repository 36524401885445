import { Text } from "@chakra-ui/react";

function Genre() {
  return (
    <>
      <Text as="b" fontSize="3xl">
        Genre page
      </Text>
    </>
  );
}

export default Genre;
