import { Text } from "@chakra-ui/react";

function Search() {
  return (
    <>
      <Text as="b" fontSize="3xl">
        Search page
      </Text>
    </>
  );
}

export default Search;
