import { Text } from "@chakra-ui/react";

function History() {
  return (
    <>
      <Text as="b" fontSize="3xl">
        History page
      </Text>
    </>
  );
}

export default History;
