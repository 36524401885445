export const TestHome = [
  {
    id: "1",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "2",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "3",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "4",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "5",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "6",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "7",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "8",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "9",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "10",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "11",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "12",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "13",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "14",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "15",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "16",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "17",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "18",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "19",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "20",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "21",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "22",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "23",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "24",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "25",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "26",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "27",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "28",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "29",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "30",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "31",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "32",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "33",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "34",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "35",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "36",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "37",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "38",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "39",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "40",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "41",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "42",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "43",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "44",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "45",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "46",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "47",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "48",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "49",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "50",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "51",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "52",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "53",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "54",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "55",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "56",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "57",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "58",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "59",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "60",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "61",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "62",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "63",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "64",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "65",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "66",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "67",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "68",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "69",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "70",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "71",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "72",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "73",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "74",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "75",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "76",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "77",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "78",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "79",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "80",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "81",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "82",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "83",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "84",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "85",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "86",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "87",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "88",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "89",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "90",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "91",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "92",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "93",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "94",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "95",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "96",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "97",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "98",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "99",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "100",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "101",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "102",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "103",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "104",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "105",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "106",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "107",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "108",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "109",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "110",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "111",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "112",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "113",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "114",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "115",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "116",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "117",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "118",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "119",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "120",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "121",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "122",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "123",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "124",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "125",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "126",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "127",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "128",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "129",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "130",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "131",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "132",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "133",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "134",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "135",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "136",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "137",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "138",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "139",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "140",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "141",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "142",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "143",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "144",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "145",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "146",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "147",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "148",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "149",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "150",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "151",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "152",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "153",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "154",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "155",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "156",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "157",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "158",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "159",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "160",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "161",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "162",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "163",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "164",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "165",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "166",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "167",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "168",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "169",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "170",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "171",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "172",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "173",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "174",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "175",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "176",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "177",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "178",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "179",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "180",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "181",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "182",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "183",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "184",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "185",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "186",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "187",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "188",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "189",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "190",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "191",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "192",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "193",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "194",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "195",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "196",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "197",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "198",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "199",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "200",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "201",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "202",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "203",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "204",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "205",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "206",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "207",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "208",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "209",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "210",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "211",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "212",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "213",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "214",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "215",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
  },
  {
    id: "216",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
  {
    id: "217",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
  },
  {
    id: "218",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
  },
  {
    id: "219",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
  },
  {
    id: "220",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
  },
];

export const HistoryStories = [
  {
    id: "1",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
    chap: "963",
  },
  {
    id: "2",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
  {
    id: "3",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "4",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
    chap: "1069",
  },
  {
    id: "5",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
    chap: "465",
  },
  {
    id: "6",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "7",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
];

export const NewStoriesData = [
  {
    id: "1",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
    chap: "963",
  },
  {
    id: "2",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
  {
    id: "3",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "4",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
    chap: "1069",
  },
  {
    id: "5",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
    chap: "465",
  },
  {
    id: "6",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "7",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "8",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
  {
    id: "9",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "10",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
];

export const RecommendedStoriesData = [
  {
    id: "1",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
    chap: "963",
  },
  {
    id: "2",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
  {
    id: "3",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "4",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
    chap: "1069",
  },
  {
    id: "5",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
    chap: "465",
  },
  {
    id: "6",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "7",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
];
