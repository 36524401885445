export const searchResult = [
  {
    id: "1",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
    chap: "963",
  },
  {
    id: "2",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
  {
    id: "3",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "4",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
    chap: "1069",
  },
  {
    id: "5",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
    chap: "465",
  },
  {
    id: "6",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "7",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "8",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "9",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "10",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "11",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "12",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "13",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "14",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "15",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "16",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "17",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "18",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "19",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "20",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "21",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "22",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "23",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "24",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "25",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "26",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "27",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "28",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "29",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "30",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "31",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "32",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "33",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "34",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "35",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "36",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "37",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "38",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "39",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "40",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "41",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "42",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "43",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "44",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "45",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "46",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "47",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "48",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "49",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "50",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "51",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "52",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "53",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "54",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "55",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "56",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "57",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "58",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "59",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "60",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "61",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "62",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "63",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "64",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "65",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "66",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "67",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "68",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "69",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "70",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "71",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "72",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
];

export const Genres = [
  {
    id: "1",
    genreName: "Tất cả",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
  {
    id: "2",
    genreName: "Action",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "3",
    genreName: "Adult",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "4",
    genreName: "Adventure",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "5",
    genreName: "Anime",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "6",
    genreName: "Chuyển Sinh",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "7",
    genreName: "Comedy",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "8",
    genreName: "Comic",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "9",
    genreName: "Cooking",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "10",
    genreName: "Cổ Đại",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
  {
    id: "11",
    genreName: "Doujinshi",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "12",
    genreName: "Drama",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "13",
    genreName: "Đam Mỹ",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
  {
    id: "14",
    genreName: "Ecchi",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "15",
    genreName: "Fantasy",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "16",
    genreName: "Gender Bender",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "17",
    genreName: "Harem",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "18",
    genreName: "Historical",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "19",
    genreName: "Horror",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "20",
    genreName: "Josei",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "21",
    genreName: "Live Action",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "22",
    genreName: "Manga",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "23",
    genreName: "Manhua",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
  {
    id: "24",
    genreName: "Martial Arts",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "25",
    genreName: "Mature",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "26",
    genreName: "Mecha",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "27",
    genreName: "Mystery",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "28",
    genreName: "Ngôn Tình",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
  {
    id: "29",
    genreName: "One Shot",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "30",
    genreName: "Psychological",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "31",
    genreName: "Romance",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
  {
    id: "32",
    genreName: "School Life",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "33",
    genreName: "Sci-fi",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "34",
    genreName: "Seinen",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "35",
    genreName: "Shoujo",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "36",
    genreName: "Shoujo Ai",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "37",
    genreName: "Shounen",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "38",
    genreName: "Shounen Ai",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "39",
    genreName: "Slice of Life",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "40",
    genreName: "Smut",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "41",
    genreName: "Soft Yaoi",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "42",
    genreName: "Soft Yuri",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "43",
    genreName: "Sports",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "44",
    genreName: "Supernatural",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "45",
    genreName: "Thiếu Nhi",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "46",
    genreName: "Webtoon",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "47",
    genreName: "Tragedy",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "48",
    genreName: "Trinh Thám",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "49",
    genreName: "Truyện Scan",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "50",
    genreName: "Truyện Màu",
    color: "black",
    description: "hay lắm!",
    bold: false,
  },
  {
    id: "51",
    genreName: "Xuyên Không",
    color: "black",
    description: "hay lắm!",
    bold: true,
  },
];

export const test = [
  {
    id: "1",
    imgComic: require("../assets/images/comic/doremon.jpg"),
    name: "Doremon",
    chap: "963",
  },
  {
    id: "2",
    imgComic: require("../assets/images/comic/huyet-nghiep-ki-si-chuyen-sinh.jpg"),
    name: "Huyết kiếm sĩ chuyển sinh",
    chap: "142",
  },
  {
    id: "3",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "4",
    imgComic: require("../assets/images/comic/one-piece.jpg"),
    name: "One piece",
    chap: "1069",
  },
  {
    id: "5",
    imgComic: require("../assets/images/comic/toan-chuc-phap-su.jpg"),
    name: "Toàn Chức Pháp Sư",
    chap: "465",
  },
  {
    id: "6",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "7",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "8",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "9",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
  {
    id: "10",
    imgComic: require("../assets/images/comic/nguyen-ton.jpg"),
    name: "Nguyên Tôn",
    chap: "45",
  },
];
